<template>
  <div class="container">
    <BaseTitle v-if="title" :tag="tag">{{ title }}</BaseTitle>

    <InfiniteLoading
      v-slot="{ allData: offers }"
      ref="infiniteLoadingWrapper"
      :data="data?.offers"
      :has-more-to-load="hasMoreToLoad"
      :pixel-offset="100"
      class="grid"
      @load-more="loadMore"
    >
      <template v-for="(offer, index) in offers" :key="offer.id">
        <OfferItem
          :offer="offer"
          :hotspot-id="offer.hotspot.id"
          class="offer-item"
          @click="emit('offerClicked', offer)"
        />
        <!-- Google in feed ad every 6th brochure -->
        <AdvertisementGoogleInFeed
          v-if="(index + 1) % 6 === 0"
          :key="index"
          :ad-index="(index + 1) / 6 - 1"
          :display-at="InFeedAdDisplayAt.OffersGrid"
          class="in-feed"
        />
      </template>

      <!-- No results messages -->
      <div v-if="!offers.length" class="full-width">
        <!-- No results on search -->
        <BaseText v-if="input?.search" class="bold centered-block">
          {{ $t("search.no-results") }}
        </BaseText>

        <!-- No results -->
        <BaseText v-else>
          {{ $t("offers.no-offers") }}
        </BaseText>
      </div>
    </InfiniteLoading>
  </div>
</template>

<script setup lang="ts">
import InfiniteLoading from "~/components/molecules/InfiniteLoading.vue";
import { InFeedAdDisplayAt } from "~/types";
import {
  type OffersInput,
  type PaginationInput,
  useOffersQuery,  
} from "~/graphql/generated";

const props = defineProps<{
  title?: string;
  input?: OffersInput;
  pagination: PaginationInput;
  infiniteLoading?: boolean;
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}>();

const emit = defineEmits(["offerClicked"]);

// Get the users location (if set)
const location = useLocationState();

// Mark this as reactive, so the query will re-run when the value changes
const pagination = toReactive(props.pagination);
const variables = computed(() => {
  return {
    offersInput: {
      ...props.input,
      ...(location.value &&
        !props.input?.citySlug && {
          point: {
            latitude: location.value.lat,
            longitude: location.value.long,
          },
        }),
    },
    pagination,
  };
});

const { data } = await useOffersQuery({
  variables,
});

const loadMore = () => {
  if (!props.infiniteLoading || !data?.value?.offers) return;
  pagination.offset += data?.value?.offers.length;
};

const hasMoreToLoad = computed(() => {
  if (!data?.value?.offers) return false;
  return data?.value?.offers?.length >= pagination.limit;
});

// Watch the input and reset the infinite loading
const infiniteLoadingWrapper = useTemplateRef('infiniteLoadingWrapper')

// Make sure to only show full rows of items
if(!props.infiniteLoading) {
  //@ts-expect-error - generic components are having a (type) issue
  useFullRowsOnly(infiniteLoadingWrapper, ".offer-item, .in-feed");
}

watch(
  () => props.input,
  (newValue, oldValue) => {
    // Prevent the infinite loading from resetting when the input is the same
    if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;

    infiniteLoadingWrapper.value?.resetData();
    pagination.offset = 0;
  }
);
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));

  @include for-small-screen {
    grid-template-columns: repeat(auto-fill, minmax(252px, 1fr));
  }

  @include for-extra-small-screen {
    grid-template-columns: 1fr 1fr;
  }

  .full-width {
    grid-column: 1 / -1;
  }
}

.two-columns .grid {
  grid-template-columns: 1fr 1fr;
}

.small-tiles .grid {
  grid-template-columns: repeat(auto-fill, minmax(252px, 1fr));
  @include for-extra-small-screen {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
